.agency {

    margin: 56px 16px 168px 16px;

    &-title {
        font-family: Work Sans;
        font-size: 40px;
        font-weight: 400;
        line-height: 48px;
        text-align: left;
        color: #FFFFFF;
    }

    .title {
        font-family: Work Sans;
        font-size: 20px;
        font-weight: 400;
        line-height: 30px;
        text-align: left;
        color: #EDD526;
    }

    .title-left {
        font-family: Work Sans;
        font-size: 12px;
        font-weight: 400;
        line-height: 14.08px;
        text-align: left;
        color: #FFFFFF;
    }

    .text {
        font-family: Work Sans;
        font-size: 20px;
        font-weight: 400;
        line-height: 30px;
        text-align: left;
        color: #FFFFFF;
    }

    &-logo {
        display: none;
        opacity: 0;
        min-width: 328px;
        max-width: 566px;
        width: 100%;
    }

    @screen lg {
        height: 100vh;
        margin: 80px 80px auto 80px;

        &-title {
            font-size: 80px;
            line-height: 93.84px;
        }

        .title-left {
            font-size: 16px;
            line-height: 18.77px;
        }

        .title,
        .text {
            font-size: 32px;
            line-height: 48px;
        }

    }
}