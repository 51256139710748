.craft {
    .screen {
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;

        .content {
            text-align: center;
            font-family: Work Sans;
            font-size: 20px;
            font-weight: 400;
            line-height: 30px;
        }

        @screen lg {
            .content {
                font-size: 48px;
                line-height: 56.3px;
            }
        }
    }

    .black {
        background-color: #1B1B1D;
    }

    .yellow {
        background-color: #EDD526;
    }
}