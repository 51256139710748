.custom-cursor {
    position: fixed;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: rgba(237, 213, 38, 1);
    pointer-events: none;
    mix-blend-mode: color-dodge;
    transition: transform 0.1s ease;
    transform: translate(-50%, -50%);
    z-index: 99999;
    display: none;

    @screen lg {
        display: block;
    }
}
