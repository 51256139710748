.footer {

    .first-section {
        border-top: 1px solid;
        border-bottom: 1px solid;
        border-color: rgba(66, 65, 74, 1);

        .first-list {
            margin: 24px auto 24px auto;
            display: flex;
            gap: 40px;
            flex-direction: column;

            li {
                font-family: Work Sans;
                font-weight: 400;
                font-size: 14px;
                line-height: 16.42px;
                text-align: center;
                color: rgba(255, 255, 255, 1);
                text-wrap: nowrap;
            }

            @screen md {
                margin: 60px auto 65px auto;
                flex-direction: row;

                li {
                    font-size: 16px;
                    line-height: 18.77px;
                }
            }
        }

    }

    .second-section {
        flex-direction: column;
        padding-top: 20px;
        padding-bottom: 31px;
        padding: 40px 51.1px;
        gap: 40px;


        .second-list {
            display: flex;
            gap: 40px;
            align-self: center;

            li {
                font-family: Work Sans;
                font-size: 10px;
                font-weight: 400;
                line-height: 11.73px;
                text-align: left;
                color: rgba(255, 255, 255, 1);
            }

            @screen md {

                li {
                    font-size: 12px;
                    line-height: 14.08px;
                    color: rgba(255, 255, 255, 0.4);
                }
            }
        }

        .text {
            font-family: Work Sans;
            font-size: 12px;
            font-weight: 400;
            line-height: 14.08px;
            align-content: center;
            text-align: center;
            color: rgba(255, 255, 255, 1);
        }

        @screen md {
            gap: 0;
            padding: 20px 80px 31px 80px;
            flex-direction: row;

            .text {
                font-size: 10px;
                line-height: 11.73px;

            }
        }
    }
}