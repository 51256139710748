.accordion-section {

    padding: 0px 16px 160px 16px;


    .accordion-list {
        display: grid;
        gap: 16px;
        list-style: disc;

        .item {
            font-family: Work Sans;
            font-size: 20px;
            font-weight: 400;
            line-height: 30px;
            text-align: left;
            color: #FFFFFF;
            margin-bottom: 10px;
        }

        @screen lg {
            grid-template-columns: repeat(auto-fill, minmax(580px, 1fr));
            grid-auto-rows: 1fr;
            grid-row-gap: 16px;
            margin: auto;
        }


    }

    .chevron-down {
        margin-left: auto;
        transition: transform 0.25s cubic-bezier(0, 0, 0, 1);
    }

    .szh-accordion {

        &__item {
            border-bottom: 1px solid #FFFFFF;
            margin-bottom: 40px;

            &-btn {
                cursor: pointer;
                display: flex;
                align-items: center;
                width: 100%;
                margin: 0;
                font-family: Work Sans;
                font-size: 36px;
                font-weight: 400;
                line-height: 42.23px;
                text-align: left;
                color: #FFFFFF;
                background-color: transparent;
                border: none;
                opacity: 0.4;
                padding-bottom: 8px;
            }

            &-content {
                transition: height 0.25s cubic-bezier(0, 0, 0, 1);
            }

            &-panel {
                padding-bottom: 1rem;
            }

            &:hover {
                .szh-accordion__item-btn {
                    opacity: 1;
                }
            }

            &--expanded {
                .szh-accordion__item-btn {
                    opacity: 1;
                }

                .chevron-down {
                    transform: rotate(45deg);
                }
            }
        }
    }

    @screen lg {
        padding: 120px 80px;

        .chevron-down {
            width: 56px;
        }

        .accordion-list {

            .item {
                font-size: 24px;
                line-height: 48px;
            }
        }

        .szh-accordion {

            &__item {

                &-btn {
                    font-size: 80px;
                    line-height: 93.84px;
                }
            }
        }

    }
}