@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import "./keyframes.scss";

@font-face {
    font-family: 'Work Sans';
    src: url('../fonts/WorkSans-VariableFont_wght.ttf') format('truetype');
    font-style: normal;
}

@font-face {
    font-family: 'Archivo Narrow';
    src: url('../fonts/ArchivoNarrow-VariableFont_wght.ttf') format('truetype');
    font-style: normal;
}

@font-face {
    font-family: 'Nothing You Could Do';
    src: url('../fonts/NothingYouCouldDo-Regular.ttf') format('truetype');
    font-style: normal;
}

body {
    background-color: rgba(27, 27, 29, 1);
    overflow-x: hidden;

    
}

::-webkit-scrollbar {
    display: none;
}