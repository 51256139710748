.we {
    background: rgba(237, 213, 38, 1);

    .wrap{
        margin: 0 -16px;
        padding-bottom: 60px;
        .line {
            width: 100vw;
          }
          .left, .right {
            width: 50vw;
            overflow: hidden;
            display: inline-block;
          }
          .left {
            color: rgba(231, 103, 56, 1);
            transform: skew(0deg, -15deg);
          }
          .right {
            color:#563AFF;
            transform: skew(0deg, 15deg);
          }
          .left .content {
            width: 100vw;
            text-align: center;
          }
          .right .content {
            width: 100vw;
            text-align: center;
            transform: translate(-50vw);
          }
          span {
            display: inline-block;
            font-family: 'Montserrat', sans-serif;
            font-size: 6vw;
            text-transform: uppercase;
            line-height: .8;
            transition: ease-out .6s;
          }
    }


    .marquee-text,
    .marquee-text2 {
        font-family: Archivo Narrow;
        font-size: 20px;
        font-weight: 600;
        line-height: 26.94px;
        color: rgba(237, 213, 38, 1);
        padding: 0;
    }

    .marquee-text2{
        color: rgba(57, 201, 191, 1);
    }

    &-middle {
        padding: 136px 16px 0px 16px;

        .top-text {
            font-family: Work Sans;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            text-align: left;
            color: rgba(27, 27, 29, 1);
            margin-bottom: 51px;
        }

        .title {
            font-family: Work Sans;
            font-size: 44px;
            font-weight: 400;
            line-height: 51.61px;
            text-align: center;
            color: rgba(27, 27, 29, 1);
            margin-bottom: 51px;
        }

        .title:last-of-type {
            margin-bottom: 72px;
        }

        .sub-text,
        .sub-text-bold {
            font-family: Work Sans;
            font-size: 14px;
            line-height: 20px;
            text-align: left;
            color: rgba(27, 27, 29, 1);
        }

        .sub-text-bold {
            font-weight: 700;
            font-size: 16px;
            line-height: 18.77px;
        }
    }

    .btn-23 {
        margin-top: 47px;
        width: 100%;
        max-width: 130px;
        align-self: center;
        border: 1px solid rgba(27, 27, 29, 1);
        cursor: pointer;
        font-family: Work Sans;
        font-size: 14px;
        font-weight: 400;
        line-height: 16.42px;
        margin-bottom: 100px;
        border-radius: 40px;
        overflow: hidden;
        padding: 8px;
        position: relative;

        &:disabled {
            cursor: default;
        }

        &:hover {
            background: rgba(57, 201, 191, 1);
        }

        &:hover .marquee {
            -webkit-animation-play-state: running;
            animation-play-state: running;
            opacity: 1;
        }

        svg {
            display: block;
            vertical-align: middle;
        }

        [hidden] {
            display: none;
        }

        span {
            display: grid;
            inset: 0;
            place-items: center;
            position: absolute;
            transition: opacity 0.2s ease;
        }

        .marquee {
            --spacing: 5em;
            --start: 0em;
            --end: 5em;
            -webkit-animation: marquee 1s linear infinite;
            animation: marquee 1s linear infinite;
            -webkit-animation-play-state: paused;
            animation-play-state: paused;
            opacity: 0;
            position: relative;
            text-shadow: rgba(27, 27, 29, 1) var(--spacing) 0, rgba(27, 27, 29, 1) calc(var(--spacing) * -1) 0,
                rgba(27, 27, 29, 1) calc(var(--spacing) * -2) 0;
        }

        &:hover .text {
            opacity: 0;
        }

        @-webkit-keyframes marquee {
            0% {
                transform: translateX(var(--start));
            }

            to {
                transform: translateX(var(--end));
            }
        }

        @keyframes marquee {
            0% {
                transform: translateX(var(--start));
            }

            to {
                transform: translateX(var(--end));
            }
        }
    }

    @screen lg {

        .wrap{
            margin: 0 -114px 0px -80px;
            padding-bottom: 100px;
        }
        &-middle {
            padding: 176px 114px 222px 80px;

            .top-text {
                font-size: 16px;
                font-weight: 400;
                line-height: 18.77px;
                margin-top: 172px;
                margin-bottom: 120px;
            }

            .title {
                font-size: 79px;
                font-weight: 400;
                line-height: 92.67px;
            }

            .sub-text {
                font-size: 16px;
                line-height: 24px;
            }

            .sub-text-bold {
                font-size: 16px;
                line-height: 24px;
            }

            .btn-23 {
                max-width: 143px;
                margin-top: 72px;
                font-size: 16px;
                font-weight: 400;
                line-height: 18.77px;
                padding: 11px;
            }
        }

        .marquee-text,
        .marquee-text2 {
            font-size: 64px;
            line-height: 86.21px;
        }
    }
}