.team {
    height: 70vh;

    .left {
        margin-left: 16px;
        font-family: Work Sans;
        font-size: 12px;
        font-weight: 400;
        line-height: 14.08px;
        text-align: left;
        color: #FFFFFF;
    }

    &-image{
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        margin: auto;
        z-index: 1;
        max-width: 375px;
        width: 100%;
    }

    .marquee-text,
    .marquee-text2 {
        font-family: Archivo Narrow;
        font-size: 20px;
        font-weight: 600;
        line-height: 26.94px;
        color: rgba(86, 58, 255, 1);
        padding: 0;
    }

    .marquee-text2{
        color: rgba(229, 42, 30, 1);
    }

    @screen lg {
        height: 100vh;

        .left {
            margin-left: 80px;
            font-size: 16px;
            line-height: 18.77px;
        }

        &-image{
            max-width: 859px;
        }

        .marquee-text,
        .marquee-text2 {
            font-size: 64px;
            line-height: 86.21px;
        }
    }
}