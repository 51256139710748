.fade-in-out {
    opacity: 0;
    animation: fadeInOut 1s forwards;
}

.fade-out {
    animation: fadeOut 1s forwards;
}

.fade-in-left {
    opacity: 0;
    animation: fadeInLeft 1s forwards;
}

.fade-in-left-marquee {
    opacity: 0;
    animation: fadeInLeftMarquee 1.5s forwards;
}

.fade-in-right-marquee {
    opacity: 0;
    animation: fadeInLeftMarquee 1.5s forwards;
}

.fade-in-right {
    opacity: 0;
    animation: fadeInRight 1s forwards;
}

.fade-in-up {
    opacity: 0;
    animation: fadeInUp 1s forwards;
}

.fade-in {
    opacity: 0;
    animation: fadeIn 1s forwards;
}

.fade-in-up-lum {
    opacity: 0;
    animation: fadeInUpLum 1s forwards;
}

.heartbeat {
    animation: fadeInUp 1s, heartbeat 1s;
    animation-delay: 0s, 2.5s;
}

.zoom-in {
    opacity: 0;
    display: block !important;
    animation: zoomIn 1s forwards;
}

@keyframes scaleCard {

    from {
        transform: scale(0);
    }

    to {
        transform: scale(1);
    }

}

.card-scale {
    animation: scaleCard 1.5s forwards;
}

@keyframes fadeOut {

    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}


@keyframes fadeInOut {

    0%,
    25%,
    75%,
    100% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }
}

.logo {
    opacity: 0;
    animation: fadeIn 1s forwards;
}

@keyframes zoomIn {
    from {
        opacity: 0;
        display: none;
        transform: scale(0);
    }

    to {
        opacity: 0.1;
        display: block !important;
        transform: scale(1);
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fadeInLeft {
    from {
        opacity: 0;
        transform: translateX(-100%);
    }

    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes fadeInLeftMarquee {
    from {
        opacity: 0;
        width: 0;
    }

    to {
        opacity: 1;
        width: 120%;
    }
}


@keyframes fadeInRight {
    from {
        opacity: 0;
        transform: translateX(100%);
    }

    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(100%);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeInUpLum {
    from {
        opacity: 0;
        transform: translateY(100%);
        mix-blend-mode: luminosity;
    }

    to {
        opacity: 1;
        transform: translateY(0);
        mix-blend-mode: luminosity;
    }
}

@keyframes heartbeat {
    0% {
        transform: scale(1);
    }

    20% {
        transform: scale(1.25);
        color: #fa2314;
    }

    40% {
        transform: scale(1);
        color: #fa2314;
    }

    60% {
        transform: scale(1.25);
        color: #fa2314;
    }

    80% {
        transform: scale(1);
        color: #fa2314;
    }

    100% {
        transform: scale(1);
    }
}

.delay-1s {
    animation-delay: 1s;
}

.delay-1-5s {
    animation-delay: 1.5s;
}

.delay-2s {
    animation-delay: 2s;
}

.delay-3s {
    animation-delay: 3s;
}

.delay-4s {
    animation-delay: 4s;
}