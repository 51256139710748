.learn {
    max-width: 1920px;
    margin: 120px 16px 160px 16px;

    .title {
        font-family: Work Sans;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
        color: rgba(255, 255, 255, 1);
        margin-bottom: 39px;
    }

    .text {
        max-width: 446px;
        font-family: Work Sans;
        text-align: left;
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        color: rgba(255, 255, 255, 1);
        margin-bottom: 32px;
        margin-top: 40px;
    }

    .hello {
        font-family: Work Sans;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
        color: rgba(255, 255, 255, 0.4);
    }

    .mail {
        font-family: Work Sans;
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
        text-align: left;
        color: rgba(255, 255, 255, 1);
    }

    .card-container {
        position: relative;
        max-width: 350px;
        width: 100%;
        align-self: center;

        @screen lg {
            max-width: 530px;
            max-height: 380px;
            align-self: unset;
        }

        @screen xl {
            margin-top: -184.77px;
        }
    }

    @screen lg {

        .title {
            margin-bottom: 166px;
            font-size: 16px;
            line-height: 18.77px;
        }

        .text {
            font-size: 16px;
            line-height: 18.77px;
            margin-bottom: 56px;
            font-size: 16px;
            line-height: 24px;
            margin-top: 0;
        }

        .hello {
            font-size: 16px;
            line-height: 18.77px;
        }

        .mail {
            font-size: 16px;
            line-height: 24px;
        }
    }

    @screen lg {
        margin: 120px 80px 365px 80px;
    }
}