.offerings {

    overflow: hidden;
    margin: 0 16px 160px 16px;

    .text {
        font-family: Work Sans;
        font-size: 20px;
        font-weight: 400;
        line-height: 30px;
        color: #FFFFFF;
        position: relative;
        max-width: 343px;
    }

    @screen lg {
        overflow: visible;

        margin: 94px 80px;

        .text {
            font-size: 32px;
            line-height: 48px;
            max-width: 719px;
        }

        .text:nth-child(1),
        .text:nth-child(3),
        .text:nth-child(5) {
            max-width: 719px;
        }

        .text:nth-child(2) {
            max-width: 756px;
        }

        .text:nth-child(4) {
            max-width: 750px;
        }

        .text:nth-child(6) {
            max-width: 788px;
        }
    }
}