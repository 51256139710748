.header {
    position: sticky;
    top: 0;
    z-index: 1000;
    padding: 0 16px;


    .menu-text {
        font-family: Work Sans;
        font-size: 14px;
        font-weight: 400;
        line-height: 16.42px;
        text-align: left;
    }

    .dropdown-div {
        position: relative;
        top: 12px;

        .dropdown {
            opacity: 0;
        }


        .dropdown a {
            color: #FFFFFF !important;
            opacity: 0.5;
            font-size: 14px;
            line-height: 16.42px;
        }

    }

    .dropdown-div:hover {
        top: 17px;
        .dropdown {
            top: 5px;
            opacity: 1;
            position: relative;
            background: rgba(0, 0, 0, 0.12);
            border: 1px solid #FFFFFF14;
            padding: 4px 20px 4px 12px;
            border-radius: 5px;
            backdrop-filter: blur(10px);
        }

        .dropdown-logo{
            transform: rotate(-180deg);
        }
    }


    .menu-logo {
        width: 35px;
    }

    &.header-hidden {
        transform: translateY(-100%);
        transition: all 0.5s;
    }

    &.header-visible {
        transform: translateY(0);
        transition: all 0.5s;
    }


    @keyframes szh-menu-show {
        from {
            opacity: 0;
        }
    }

    @keyframes szh-menu-hide {
        to {
            opacity: 0;
        }
    }

    .szh-menu-container {
        top: 0;

        .szh-menu {
            user-select: none;
            border-bottom-left-radius: 16px;
            border-bottom-right-radius: 16px;
            padding: 24px;
            width: 100vw;
            background-color: #FFFFFF;
            top: 0 !important;
            padding-top: 64px;
            position: relative;

            .burger-list {
                font-family: Work Sans;
                font-size: 24px;
                font-weight: 400;
                line-height: 28.15px;
                text-align: center;
                color: #1B1B1D;
                gap: 16px;
            }

            .social-list {
                font-family: Work Sans;
                font-size: 10px;
                font-weight: 400;
                line-height: 11.73px;
                text-align: center;
                color: #1B1B1D;
                gap: 40px;
                justify-content: center;
            }

            .divider {
                height: 1px;
                background: #E8E8EC;
                margin-top: 24px;
                margin-bottom: 16px;
                margin-left: -24px;
                width: calc(100% + 48px);
            }

            &--state-opening {
                animation: szh-menu-show 0.5s ease-out;

            }

            &--state-closing {
                animation: szh-menu-hide 0.5s ease-out forwards;
            }

        }

    }


}