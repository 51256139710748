.beyond {
    background: rgba(27, 27, 29, 1);
    position: relative;
    width: 100%;
    min-height: 100vh;

    .title {
        font-family: Work Sans;
        font-size: 12px;
        font-weight: 400;
        line-height: 14.08px;
        text-align: left;
        color: #FFFFFF;
    }

    .subtext {
        font-family: Work Sans;
        font-size: 20px;
        font-weight: 400;
        line-height: 30px;
        text-align: left;
        color: #FFFFFF;
        margin-top: 49px;
    }

    .space-holder {
        position: relative;
        width: 100%;

        .sticky {
            position: sticky;
            top: 120px;
            height: calc(100vh + 120px);
            width: 100%;
            overflow-x: hidden;

            .horizontal {
                position: absolute;
                height: 100%;
                will-change: transform;
                overflow: hidden;

                .text-div {
                    position: relative;
                    height: 100%;

                    .text {
                        position: relative;
                        font-family: Work Sans;
                        font-size: 80px;
                        font-weight: 300;
                        line-height: 93.84px;
                        text-align: left;
                        color: rgba(255, 255, 255, 1);
                        white-space: nowrap;
                        top: calc(70vh - 400px);
                    }
                }
            }
        }




    }


    @screen lg {

        .title {
            font-size: 16px;
            line-height: 18.77px;
        }

        .subtext {
            font-size: 72px;
            line-height: 84px;
        }

        .space-holder {
            .sticky {
                .horizontal {
                    .text-div {
                        .text {
                            font-size: 286px;
                            line-height: 335px;
                            top: calc(70vh - 500px);
                        }
                    }
                }
            }
        }
    }
}