.references {


    .ref-screen {
        text-align: center;
        display: flex;
        flex-direction: column;
        place-items: center;
        padding: 120px 0px 232px 0px;
        height: 100%;

        .left {
            font-family: Work Sans;
            font-size: 12px;
            font-weight: 400;
            line-height: 14.08px;
            text-align: left;
            color: #FFFFFF;
            margin-left: 16px;
            align-self: flex-start;
            margin-bottom: 20px;
        }


        .ref-slide {
            align-items: center;

            img {
                width: fit-content;
                height: fit-content;
                align-items: flex-end;
            }

            .slide-div {
                width: 1px;
                height: 60px;
                background: #303030;
            }

        }

        .title {
            font-family: Work Sans;
            font-size: 40px;
            font-weight: 400;
            line-height: 48px;
            text-align: center;
            color: #FFFFFF;
        }


        @screen lg {

            .title {
                font-size: 80px;
                line-height: 93.84px;
            }
        }

    }

    @screen lg {

        .ref-screen {
            display: grid;
            place-items: center;

            .left {
                font-size: 16px;
                line-height: 18.77px;
            }
        }
    }

    @screen xl {
        margin: 0 92px;
    }

}