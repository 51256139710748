.help {

    margin: 40px 16px 160px 16px;

    .title {
        font-family: Work Sans;
        font-size: 40px;
        font-weight: 400;
        line-height: 48px;
        text-align: left;
        color: #FFFFFF;
        margin-bottom: 16px;
    }

    .text {
        font-family: Work Sans;
        font-size: 20px;
        font-weight: 400;
        line-height: 30px;
        text-align: left;
        color: #FFFFFF;
    }

    @screen lg {

        margin: 120px 80px 212px 80px;

        .title {
            font-size: 80px;
            line-height: 93.84px;
            margin-bottom: 40px;
        }

        .text {
            font-size: 32px;
            line-height: 48px;
        }

    }

}