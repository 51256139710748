.accordion-card {
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    padding: 14px 10px;
    display: flex;
    gap: 12px;
    flex-direction: column;
    margin: auto;

    &-title {
        font-family: Work Sans;
        font-size: 18px;
        font-weight: 500;
        line-height: 20px;
        text-align: left;
    }

    &-text {
        font-family: Work Sans;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        text-align: left;
    }

    @screen lg {
    height: 155px;


        padding: 15px 20px;

        &-title {
            font-size: 24px;
            line-height: 26px;
        }

        &-text {
            font-size: 17px;
            line-height: 23px;
        }
    }
}