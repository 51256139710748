.leads {
    margin: 0 16px 91px 16px;

    .title {
        font-family: Work Sans;
        font-size: 12px;
        font-weight: 400;
        line-height: 14.08px;
        text-align: left;
        color: #FFFFFF;
    }

    .text {
        margin: auto;
        max-width: max-content;
        font-family: Nothing You Could Do;
        font-size: 72px;
        font-weight: 400;
        line-height: 72px;
        text-align: center;
        margin-top: 96px;
        color: #FFFFFF;

        .image-div {
            position: absolute;
            width: 80px;

            .image {
                position: relative;
                width: 100%;
                transition: transform 0.8s;
                transform-style: preserve-3d;
            }


        }

        .first {
            bottom: 0px;
            left: 8px;

            &::after {
                content: "NAGEHAN GÜNEŞ OKMAN";
                background: #563AFF;
                padding: 1px 5px 1px 1px;
                font-family: Archivo Narrow;
                display: block;
                font-size: 10px;
                line-height: 10px;
                font-weight: 600;
                color: #42CD00;
                text-align: start;
                letter-spacing: -0.02em;
                width: fit-content;
                text-wrap: nowrap;
                margin-left: -18px;
                text-align: center;

                @screen lg {
                    display: none;
                }
            }
        }

        .second {
            left: 15px;
            right: 0;
            top: -40px;
            margin: auto;

            &::after {
                content: "ASLI CANER";
                background: #EDD526;
                padding: 1px 0px 1px 1px;
                font-family: Archivo Narrow;
                display: block;
                font-size: 10px;
                line-height: 10px;
                font-weight: 600;
                color: #E76738;
                text-align: start;
                letter-spacing: -0.02em;
                text-wrap: nowrap;
                text-align: center;
                width: fit-content;
                margin-left: 10px;

                @screen lg {
                    display: none;
                }
            }
        }

        .last {
            bottom: 15px;
            right: -17px;

            &::after {
                content: "MİHRİBAN ERSİN";
                background: #E9CBF1;
                padding: 1px 0px 1px 1px;
                font-family: Archivo Narrow;
                display: block;
                font-size: 10px;
                line-height: 10px;
                font-weight: 600;
                color: #6167F7;
                text-align: start;
                letter-spacing: -0.02em;
                text-wrap: nowrap;
                text-align: center;
                width: fit-content;

                @screen lg {
                    display: none;
                }
            }
        }

    }

    @screen lg{
        margin: 0 80px 280px 80px;

        .card {
            position: absolute;
            width: 232px;
            height: 241px;
            perspective: 500px;

            .content {
                position: absolute;
                width: 100%;
                height: 100%;

                transition: transform 1s;
                transform-style: preserve-3d;



                .front,
                .back {
                    position: absolute;
                    height: 100%;
                    width: 100%;
                    line-height: 300px;
                    color: #03446A;
                    text-align: center;
                    font-size: 60px;
                    backface-visibility: hidden;
                }

                .back {
                    color: white;
                    transform: rotateY(180deg);
                    border-radius: 50%;

                    span {
                        font-family: Archivo Narrow;
                        font-size: 40px;
                        font-weight: 600;
                        line-height: 50px;
                        text-align: left;
                        display: flex;
                        max-width: 180px;
                        margin: auto;
                    }
                }

            }
        }

        .card:hover .content {
            transform: rotateY(180deg);
            transition: transform 0.5s;
        }

        .card:first-of-type {

            .back {
                background: #563AFF;

                span {
                    padding: 30px 0px 0px 6px;
                    color: #42CD00;
                }
            }
        }

        .card:nth-of-type(2) {

            .back {
                background: #EDD526;

                span {
                    padding: 30px 0px 0px 6px;
                    color: #E76738;
                }
            }
        }

        .card:nth-of-type(3) {

            .back {
                background: #E9CBF1;

                span {
                    padding: 30px 0px 0px 6px;
                    color: #6167F7;
                }
            }
        }


        .title {
            font-size: 16px;
            line-height: 18.77px;
        }

        .text {
            font-size: 180px;
            line-height: 180px;


            .card:first-of-type {
                left: -110px;
                bottom: -30px;
            }

            .card:nth-of-type(2) {
                left: 215px;
                top: -60px;
            }

            .card:nth-of-type(3) {
                right: -70px;
                bottom: 40px;
            }

        }
    }

    @screen xl {


        .card {
            width: 281px;
            height: 292px;
            perspective: 500px;

            .content {

                .front,
                .back {
                    line-height: 300px;
                    font-size: 60px;
                }

                .back {

                    span {
                        font-size: 50px;
                        font-weight: 600;
                        line-height: 65px;
                        max-width: 215px;
                    }
                }

            }
        }

        .title {
            font-size: 16px;
            line-height: 18.77px;
        }

        .text {
            font-size: 180px;
            line-height: 180px;


            .card:first-of-type {
                left: -170px;
                bottom: -50px;

                .back {
                    span {
                        padding-top: 40px;
                    }
                }
            }

            .card:nth-of-type(2) {
                left: 215px;
                top: -60px;

                .back {
                    span {
                        padding-top: 50px;
                    }
                }
            }

            .card:nth-of-type(3) {
                right: -170px;
                bottom: 40px;

                .back {
                    span {
                        padding-top: 50px;
                    }
                }
            }

        }
    }
}