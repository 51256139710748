.hero-slider {

    height: calc(100vh - 40px);


    @screen lg {
        height: calc(100vh - 80px);

        .logo {
            &-yellow {

                &:hover {
                    color: #EDD526;
                    fill: #EDD526;
                }
            }

            &-white {
                &:hover {
                    color: #FFFFFF;
                    fill: #FFFFFF;
                }
            }

            &-pink {
                &:hover {
                    color: #F3CCF3;
                    fill: #F3CCF3;
                }
            }

            &-purple {
                &:hover {
                    color: #5E67FB;
                    fill: #5E67FB;
                }
            }

            &-orange {
                &:hover {
                    color: #FF6732;
                    fill: #FF6732;
                }
            }
        }
    }

}