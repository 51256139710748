.blending {

    padding: 130px 16px 160px 16px;

    .text {
        font-family: Work Sans;
        font-size: 20px;
        font-weight: 400;
        line-height: 30px;
        color: #FFFFFF;
    }

    .text:nth-child(1) {
        max-width: 1043px;
    }

    .text:nth-child(2) {
        max-width: 1000px;
    }

    .text:nth-child(3) {
        max-width: 709px;

    }

    @screen lg {

        padding: 134px 80px 120px 80px;

        .text {
            font-size: 32px;
            line-height: 48px;
        }
    }
}