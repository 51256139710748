.video-section {
    position: relative;

    height: calc(100vh - 40px);
    overflow: hidden;
    width: 100vw;

    .video-bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    @screen lg {

        .video-bg {
            object-fit: fill;
        } 
        height: calc(100vh - 80px);
    }
}