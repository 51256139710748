.believe {
    background: rgba(27, 27, 29, 1);
    height: 110vh;
    display: flex;
    flex-direction: column;
    padding: 16px;

    .top-text {
        font-family: Work Sans;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
        color: rgba(255, 255, 255, 1);
        margin-top: 200px;
        margin-bottom: 200px;
    }

    &-middle {

        .title {
            font-family: Archivo Narrow;
            font-size: 20px;
            font-weight: 600;
            line-height: 26.94px;
            letter-spacing: -0.02em;
            text-align: center;
            margin-bottom: 17px;
            color: rgba(255, 255, 255, 1);
            text-wrap: nowrap;
        }

        .text {
            font-family: Nothing You Could Do;
            font-size: 40px;
            font-weight: 400;
            line-height: 60px;
            text-align: center;
            color: rgba(255, 255, 255, 1);
            text-wrap: nowrap;
        }
    }

    @screen lg {
        height: 180vh;
        padding: 16px;

        .title {
            font-size: 50px;
            line-height: 67.35px;
            letter-spacing: 0;
            margin-bottom: 37px;
        }

        .top-text {
            font-size: 16px;
            line-height: 18.77px;
            margin-top: 300px;
            margin-bottom: 300px;
        }

        .text {
            font-size: 124px;
            line-height: 164px;
        }
    }

    @screen xl {
        padding: 80px;
    }

    @screen 2xl {
        height: 150vh;
    }
}