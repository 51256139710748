.brand {
    background: rgba(233, 203, 241, 1);
    padding: 136px 16px 196px 16px;

    .title {
        font-family: Work Sans;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
        color: rgba(27, 27, 29, 1);
        margin-bottom: 40px;
    }

    .text {
        font-family: Work Sans;
        font-size: 14px;
        font-weight: 300;
        line-height: 20px;
        text-align: left;
        color: rgba(27, 27, 29, 1);
        margin-bottom: 40px;
    }

    .btn-23 {
        margin-top: 40px;
        width: 100%;
        max-width: 206px;
        align-self: center;
        border: 1px solid rgba(27, 27, 29, 1);
        cursor: pointer;
        font-family: Work Sans;
        font-size: 14px;
        font-weight: 400;
        line-height: 16.42px;
        margin-bottom: 100px;
        border-radius: 40px;
        overflow: hidden;
        position: relative;
        text-wrap: nowrap;
        padding: 12px 36px;

        &:disabled {
            cursor: default;
        }

        &:hover {
            background: rgba(57, 201, 191, 1);
        }

        &:hover .marquee {
            -webkit-animation-play-state: running;
            animation-play-state: running;
            opacity: 1;
        }

        svg {
            display: block;
            vertical-align: middle;
        }

        [hidden] {
            display: none;
        }

        span {
            display: grid;
            inset: 0;
            place-items: center;
            position: absolute;
            transition: opacity 0.2s ease;
        }

        .marquee {
            --spacing: 10em;
            --start: 0em;
            --end: 10em;
            -webkit-animation: marquee 1s linear infinite;
            animation: marquee 1s linear infinite;
            -webkit-animation-play-state: paused;
            animation-play-state: paused;
            opacity: 0;
            position: relative;
            text-shadow: rgba(27, 27, 29, 1) var(--spacing) 0, rgba(27, 27, 29, 1) calc(var(--spacing) * -1) 0,
                rgba(27, 27, 29, 1) calc(var(--spacing) * -2) 0;
        }

        &:hover .text-btn {
            opacity: 0;
        }

        @-webkit-keyframes marquee {
            0% {
                transform: translateX(var(--start));
            }

            to {
                transform: translateX(var(--end));
            }
        }

        @keyframes marquee {
            0% {
                transform: translateX(var(--start));
            }

            to {
                transform: translateX(var(--end));
            }
        }
    }


    .num {
        font-size: 72px;
        font-weight: 700;
        line-height: 96.98px;
        text-align: left;
        color: rgba(27, 27, 29, 0.08);
        font-family: Archivo Narrow;
        margin-right: 12px;
    }

    .num-text {
        font-family: Work Sans;
        font-size: 30px;
        font-weight: 400;
        line-height: 35.19px;
        text-align: left;
        color: rgba(27, 27, 29, 1);
        opacity: 100%;
    }

    li {
        outline: none;
    }


    @screen lg {
        padding: 120px 80px 108px 80px;

        .title {
            font-size: 16px;
            line-height: 18.77px;
        }

        .text {
            font-size: 24px;
            line-height: 40px;
            font-family: Work Sans;
        }

        .btn-23 {
            max-width: 249px;
            font-size: 16px !important;
            font-weight: 400;
            line-height: 18.77px !important;
            padding: 16px 48px;
        }

        .num {
            font-size: 120px;
            line-height: 144px;
        }

        .num-text {
            font-size: 54px;
            line-height: 54px;
        }
    }

    @screen xl {

        padding: 120px 120px 108px 80px;
    }

}